.Wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Left {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  padding-right: 8%;
}

.Title {
  color: #447323;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
}

.Desc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.Right {
  width: 43%;
  position: relative;
}

.BackgroundImage {
  height: 660px;
  width: 100%;
  object-fit: cover;
  border-bottom-left-radius: 30px;
}

.floatImage {
  width: 55%;
  position: absolute;
  bottom: 5%;
  left: 15%;
  object-fit: contain;
}

.Certificate {
  margin-top: 100px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
  height: auto;
}

.CertWrapper {
  width: 40%;
}

.CertificateImg {
  margin-top: 30px;
  width: 100%;
  object-fit: cover;
}

.ContentBox {
  margin: 20px 10%;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

@media only screen and (max-width: 770px) {
  .Wrapper {
    flex-direction: column;
    height: auto;
  }

  .Left {
    width: 100%;
    padding: 0 16px;
    margin: 0;
  }

  .Right {
    margin-top: 50px;
    width: 100%;
  }

  .floatImage {
    top: -10%;
    width: 40%;
    left: 32%;
  }

  .BackgroundImage {
    height: auto;
    aspect-ratio: 1/1;
  }
  .Certificate {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
    height: auto;
  }

  .CertWrapper {
    width: 90%;
  }

  .CertificateImg {
    width: 100%;
    object-fit: cover;
  }

  .ContentBox {
    width: 100%;
    gap: 15px;
  }
}
