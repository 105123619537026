.Wrapper {
  width: 48%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.ProductImageWrapper {
  aspect-ratio: 1 / 1;
  display: flex;
  justify-content: center;
  background: rgba(244, 244, 244);
  border-radius: 4px;
}
.ProductImage {
  width: 65%;
  margin: auto;
}
.MainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.Title {
  color: rgba(225, 100, 28, 1);
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
}
.StarsWrapper {
  display: flex;
}
.Star {
  width: 20px;
}
.Desc {
  margin-bottom: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
}
.Footer {
  width: 66%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.Price {
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;
  color: rgba(68, 115, 35, 1);
  white-space: nowrap;
}
.More {
  min-width: 180px;
  width: 50%;
  border-radius: 8px;
  text-align: center;
  line-height: 40px;
  background: linear-gradient(224.68deg, #e2b784 13.67%, #fde8c7 84.37%);
}

@media (max-width: 699px) {
  .Wrapper {
    width: 100%;
  }
}
