.MobileResponsive {
  display: none;
}

@media only screen and (max-width: 770px) {
  .MobileResponsive {
    display: block;
  }
  .DesktopResponsive {
    display: none;
  }
}
