.Wrapper {
  padding: 0 156px;
  margin-top: 100px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.Left {
  width: 49%;
}
.Right {
  width: 49%;
  height: fit-content;
  background-color: var(--primary-color-2);
  border-radius: 8px;
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 57px;
  padding-bottom: 34px;
}

.Title {
  font-size: 32px;
  font-weight: 600;
  color: #262626;
}

.Divider {
  margin-top: 21px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background-color: #262626;
}

.Input {
  border-radius: 8px;
}

.BtnSubmit {
  width: 264px;
  height: 51px;
  background-color: #e1641c;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
}

.BtnWrapper {
  width: 100%;
  display: flex;
  justify-content: end;
}

.RightInfo {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #fff;
}

.DividerRight {
  height: 1px;
  width: 100%;
  background-color: #fff;
  margin-top: 38px;
  margin-bottom: 25px;
}

.WrapperLogo {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Logo {
  width: 256px;
  height: 132px;
  margin-left: 22%;
  margin-right: 22%;
}

@media (min-width: 910px) and (max-width: 1200px) {
  .Wrapper {
    padding: 0 50px;
  }
}

@media only screen and (max-width: 910px) {
  .Wrapper {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 500px) {
  .Wrapper {
    padding: 0 20px;
  }

  .Left {
    width: 100%;
  }
  .Right {
    width: 100%;
    margin-top: 24px;
  }

  .BtnSubmit {
    width: 191px;
  }
}
