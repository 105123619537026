.Wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 46px;
  margin-top: 41px;
}

.Text {
  display: flex;
  flex-direction: column;
  width: 95%;
  padding: 0 16px;
  margin-bottom: 86px;
}

.Title {
  color: #447323;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 19px;
}

.Desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.Image {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
}

.BackgroundImage {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.floatImage {
  position: absolute;
  top: -8%;
  width: 68%;
  height: auto;
}
