.Wrapper {
  margin-top: 0;
  padding: 0 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TopContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.TopContentTitle {
  color: #447323;
  font: 400px;
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 16px;
  text-align: center;
}

.TopContentDesc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-align: center;
}

.ContentWrapper {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-wrap: wrap;
  margin-top: 40px;
  width: 100%;
}

.Content {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 48px;
}

.ImageWrapper {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 48px;
}

.Number {
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(20%);
  font-weight: 400;
  font-size: 128px;
  line-height: 72px;
  color: #e1641c;
  z-index: 1;
}

.ContentImage {
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.ContentTitle {
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #447323;
}

.ContentDesc {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}

.BtnSeeMore {
  margin-top: -10px;
  width: 192px;
  height: 51px;
  border-radius: 8px;
  background-color: #e1641c;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.BtnSeeMore:hover {
  opacity: 0.9;
}
