.Container {
  display: flex;
  flex-direction: column;
  padding: 0px 10% 0;
  gap: 110px;
  background: center / contain no-repeat
    url("../../../../assets/images/Background6.png");
  background-position: 0 26px;
}
.HeaderWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.Title {
  width: 35%;
  font-size: 42px;
  color: rgba(225, 100, 28, 1);
  line-height: 60px;
  font-weight: 400;
  margin-bottom: 0;
}
.Desc {
  width: 60%;
  font-size: 24px;
  line-height: 1.5;
  font-weight: 500;
  color: #262626;
  text-align: center;
}
.ProductsList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  row-gap: 72px;
  padding: 32px;
  border: 1px solid rgba(68, 115, 35);
  border-radius: 8px;
  background: #ffffff;
  justify-content: center;
}
.Product {
  width: 48%;
}

@media (max-width: 699px) {
  .Container {
    background-position: 0 154px;
    padding-top: 50px;
    padding: 0px 3% 0;
  }
  .Product {
    width: 100%;
  }
  .ProductsList {
    padding: 20px;
    row-gap: 90px;
  }
  .HeaderWrapper {
    flex-direction: column;
    margin-bottom: -40px;
  }
  .Title {
    font-size: 36px;
    width: 100%;
    text-align: center;
    line-height: 46px;
  }
  .Desc {
    width: 100%;
    font-size: 16px;
  }
}
