.HeaderTop {
  width: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  line-height: 36px;
  background-color: var(--primary-color);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  padding-left: 156px;
  padding-right: 156px;
}

.HeaderLeftInfo {
  display: flex;
  align-items: center;
}

.Divider {
  width: 2px;
  height: 24px;
  background-color: #fff;
  margin-left: 13px;
  margin-right: 13px;
}

.Icon {
  width: 20px;
  height: 20px;
  margin-right: 7px;
}

.HeaderRightInfo {
  position: relative;
}

.HeaderRightInfoClick {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.SelectLanguageWrapper {
  position: absolute;
  top: 40px;
  left: 0;
  background-color: var(--primary-color);
  border-radius: 5px;
}

.SelectLanguageItem {
  padding: 0px 10px;
  cursor: pointer;
}

.HeaderBottom {
  box-sizing: border-box;
  width: 100%;
  top: 36px;
  left: 0;
  z-index: 999;
  background-color: var(--primary-color-2);
  padding-left: 156px;
  padding-right: 156px;
  display: flex;
  align-items: center;
  gap: 8%;
}

.HeaderBottomIconRight {
  display: flex;
  gap: 16px;
}

.IconHeaderBottom {
  width: 24px;
  height: 24px;
}

.NavBarItem {
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.NavBarItemActive,
.MobileMenuItemActive {
  font-weight: 700;
}

.NavBarWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  gap: 21px;
}

.LinkWrapper {
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 30px;
}

.BottomWhite {
  height: 3px;
  background-color: #fff;
}

.EquipmentMachinery {
  cursor: pointer;
  position: relative;
}

.SubMenu {
  background-color: var(--primary-color-2);
  position: absolute;
  top: 55px;
  display: none;
  flex-direction: column;
  padding: 10px;
  z-index: 10;
  border-radius: 5px;
}

.SubMenuShow {
  display: flex;
  gap: 5px;
}

.SubMenuItem {
  padding: 5px;
}

.MenuMobile {
  display: none;
}

.MobileMenu {
  position: absolute;
  background-color: var(--primary-color-2);
  display: none;
  top: 70px;
  left: 6%;
  flex-direction: column;
  display: none;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  padding: 10px 5px;
  border-radius: 5px;
}

.MobileMenuShow {
  display: none;
}

@media (min-width: 910px) and (max-width: 1200px) {
  .HeaderTop,
  .HeaderBottom {
    padding-left: 15px;
    padding-right: 15px;
  }

  .HeaderTop {
    justify-content: space-between;
  }

  .NavBarWrapper {
    width: 80%;
  }

  .HeaderBottom {
    gap: 5%;
  }
}

.HideMobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 910px) {
  .NavBarWrapper {
    display: none;
  }

  .HeaderTop {
    padding-left: 10px;
    padding-right: 10px;
    font-size: 14px;
  }

  .HeaderBottom {
    top: 0;
    display: flex;
    justify-content: center;
    height: 64px;
    position: relative;
  }
  .Logo {
    width: 83px;
    height: 40px;
    min-width: 83px;
    min-height: 40px;
  }

  .MenuMobile {
    position: absolute;
    display: block;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 8%;
  }

  .MobileMenuItem {
    padding: 5px;
  }

  .MobileMenuShow {
    display: flex;
  }

  .SelectLanguageWrapper {
    z-index: 1000;
    left: -30px;
  }

  .HideMobile {
    display: none;
  }
}
