.Wrapper {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}

.Container {
  display: flex;
  flex-direction: column;
  width: 60%;
  gap: 20px;
}

.ListItem {
  padding: 20px;
  border-radius: 10px;
  border: 2px solid green;
  background-color: white;
}

.ListItemSelected {
  background-color: #efffea;
}

.MainItem {
  display: flex;
  justify-content: space-between;
}

.SubItem {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
  padding: 20px;
  border-radius: 10px;
  border: 2px solid green;
}

.titleEdit {
  width: 65%;
  font-weight: bold;
  color: green;
}

.contentEdit {
  width: 70%;
  margin-top: 20px;
}

.ImgEdit {
  padding: 5px;
  width: 30px;
  object-fit: contain;
}

.IcoWrapper {
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .Container {
    width: 90%;
  }
}
