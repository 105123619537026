.MobileResponsive {
  display: none;
}

.Wrapper {
  display: flex;
  justify-content: space-between;
  margin: 10% 0 10% 10%;
}

.Left {
  width: 40%;
  display: flex;
  flex-direction: column;
  margin-right: 8%;
}

.Title {
  color: #447323;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
}

.Desc {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.BackgroundImage {
  height: 90%;
  aspect-ratio: 1.8/1;
  object-fit: cover;
  border-bottom-left-radius: 30px;
}

@media only screen and (max-width: 770px) {
  .MobileResponsive {
    display: block;
  }

  .DesktopResponsive {
    display: none;
  }
}
