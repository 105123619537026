.Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 213px;
  height: auto;
}
.TopContent {
  // display: flex;
  margin-bottom: 50px;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  display: none;
}

.TopContentTitle {
  width: 70%;
  color: #447323;
  font-size: 36px;
  margin-bottom: 16px;
  text-align: center;
}

.TopContentDesc {
  width: 70%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-align: center;
}
.Background {
  width: 100%;
  object-fit: cover;
}
.Content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.Infos {
  position: absolute;
  bottom: -10%;
  width: 80%;
  display: flex;
  justify-content: space-around;
}
.Info {
  width: 23.4%;
  aspect-ratio: 1/1;
  border-radius: 8px;
  border: 1px solid #e1641c;
  opacity: 0.9;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 1;
}
.InfoNumber {
  font-weight: 400;
  font-size: 80px;
  line-height: 72px;
  color: #e1641c;
}

.InfoText {
  margin-top: 24px;
  width: 80%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #262626;
}
.Icon {
  position: absolute;
  width: 72px;
  height: 72px;
  bottom: -36px;
  background-color: white;
  padding: 10px;
  z-index: 2;
}

@media only screen and (max-width: 770px) {
  .Infos {
    flex-wrap: wrap;
    width: 90%;
  }
  .Info {
    width: 40%;
    aspect-ratio: 1/1.5;
    margin: 5%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .InfoText {
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
  }
  .Background {
    aspect-ratio: 1/1.2;
  }
}
