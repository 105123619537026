.Wrapper {
  margin-top: 100px;
  padding: 0 156px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TopContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 223px;
  padding-right: 223px;
}

.TopContentTitle {
  color: #447323;
  font: 400px;
  font-size: 36px;
  margin-bottom: 16px;
  text-align: center;
}

.TopContentDesc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-align: center;
}

.WrapperItem {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}

.Item {
  width: 22%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.Image {
  width: 72px;
  height: 72px;
}

.Title {
  margin-top: 20px;
  font-weight: 500;
  font-size: 20px;
  color: #447323;
  line-height: 29px;
  text-align: center;
}

.Desc {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  color: #262626;
  line-height: 20px;
  text-align: center;
}
