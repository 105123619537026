.Wrapper {
  // margin-top: 100px;
  position: relative;
}

.Background {
  height: 680px;
  position: absolute;
  top: 100px;
}

.Content {
  position: absolute;
  top: 0;
  display: flex;
  justify-content: space-between;
}

.ContentLeft {
  width: 40%;
  color: #e1641c;
  font-weight: 600;
  font-size: 42px;
}

.ContentRight {
  width: 50%;
  line-height: 32px;
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.Products {
  display: flex;
  justify-content: center;
  position: absolute;
  top: 422px;
  width: 100%;
}

.ProductDetail {
  width: 80%;
  background-color: white;
  border: 1px solid #447323;
  border-radius: 8px;
  margin: 0 156px;
  padding: 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 20px;
}

.ProductWrapper {
  width: 46%;
}

.ProductContent {
  margin-top: 25px;
}

.ProductTitle {
  font-weight: 500;
  font-size: 24px;
  color: #e1641c;
}

.ImageProductWrapper {
  height: 450px;
  background-color: #f4f4f4;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.ProductImage {
  width: 329px;
  height: 287px;
}

.ProductRate {
  margin-top: 10px;
  display: flex;
  gap: 4px;
}

.ProductDesc {
  margin-top: 10px;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  color: #262626;
}

.ProductPrice {
  margin-top: 25px;
  display: flex;
  font-weight: 500;
  font-size: 22px;
  color: #447323;
  gap: 4px;
}

.BuyNow {
  margin-top: 10px;
  width: 168px;
  height: 40px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #262626;
  font-weight: 500;
  font-size: 16px;
  background: linear-gradient(#e2b784, #fde8c7);
  cursor: pointer;
}

@media only screen and (max-width: 770px) {
  .Content {
    top: 150px;
    width: 100%;
    display: block;
    text-align: center;
  }
  .ContentLeft {
    width: 100%;
  }

  .ContentRight {
    width: 100%;
  }
}
