.Container {
  padding: 120px 10% 0;
}

.ProductsList {
  display: flex;
  flex-wrap: wrap;
  column-gap: 4%;
  row-gap: 72px;
  padding: 32px;
  border: 1px solid rgba(68, 115, 35);
  border-radius: 8px;
  background: #ffffff;
  justify-content: center;
}

.Title {
  color: #447323;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  text-align: center;
  margin-bottom: 32px;
}

@media (max-width: 699px) {
  .Container {
    background-position: 0 154px;
    padding-top: 50px;
  }
  .Product {
    width: 100%;
  }
  .ProductsList {
    padding: 20px;
    row-gap: 50px;
  }
  .HeaderWrapper {
    flex-direction: column;
  }

  .Desc {
    width: 100%;
    font-size: 16px;
  }
}
