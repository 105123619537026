@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
	--foreground-rgb: 0, 0, 0;
	--background-start-rgb: 214, 219, 220;
	--background-end-rgb: 255, 255, 255;

	--primary-color: #e1641c;
	--primary-color-2: #2c7c89;
}

@media (prefers-color-scheme: dark) {
	:root {
		--foreground-rgb: 255, 255, 255;
		--background-start-rgb: 0, 0, 0;
		--background-end-rgb: 0, 0, 0;
	}
}

.ant-checkbox .ant-checkbox-inner {
	width: 24px;
	height: 24px;
}

.ant-checkbox-inner::after {
	margin-left: 2px;
}

/* custom antd table */
.ant-table {
	border-radius: 8px !important;
	background: #fff;
	box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.25);
}

.ant-table .ant-table-thead .ant-table-cell {
	color: #9095a1;
	font-size: 16px;
	font-weight: 700;
}

.ant-table .ant-table-tbody .ant-table-cell {
	color: #252525;
	font-size: 16px;
	font-weight: 500;
}

.ant-table-wrapper .ant-table-tbody>tr>td {
	border-color: #d9d9d9 !important;
}

.ant-table-wrapper .ant-table-thead>tr>th {
	border-color: #d9d9d9 !important;
}

.ant-table-body {
	overflow: auto !important;
}

/* Custom Antd Modal */
.ant-modal-content {
	border-radius: 24px !important;
}

.IconFloat1 {
	position: fixed;
	right: 30px;
	bottom: 90px;
	width: 50px;
	height: 50px;
}

.IconFloat2 {
	position: fixed;
	right: 30px;
	bottom: 30px;
	width: 50px;
	height: 50px;
}

.underDiv {
	height: 126px;
}

@media (max-width: 699px) {
	.IconFloat1 {
		right: 10px;
		bottom: 70px;
	}

	.IconFloat2 {
		right: 10px;
		bottom: 20px;
	}

	.underDiv {
		height: 100px;
	}
}