.Wrapper {
  margin-bottom: 139px;
}

.WrapperMobile {
  display: none;
}

.Title {
  color: #447323;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
}

.Desc {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.TopBox {
  display: flex;
  justify-content: space-between;
  gap: 8%;
}

.BottomBox {
  width: 100%;
  height: 536px;
  display: flex;
  justify-content: space-between;
  margin-top: 0px;
  gap: 8%;
  margin-bottom: 139px;
}

.Left {
  position: relative;
  width: 48%;
  display: flex;
  align-items: center;
}

.Right {
  width: 458px;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.MergeTea {
  position: absolute;
  left: 55%;
  height: 70%;
  display: flex;
  justify-content: end;
  gap: 10px;
}

.TextLeft {
  width: 458px;
  height: auto;
  margin-left: 10%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.TitleLeft {
  color: #447323;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
}

.DescLeft {
  margin-top: 16px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}
.ImageRight {
  width: 48%;
  height: auto;
  position: relative;
}

.BgRight {
  position: absolute;
  right: 0px;
  z-index: -1;
}

.ImgRight {
  width: 50%;
  object-fit: contain;
  position: absolute;
  right: 50%;
  top: 0;
  z-index: 1;
}

@media (min-width: 769px) and (max-width: 900px) {
  .ImageRight {
    top: 20px;
  }
}

@media only screen and (max-width: 768px) {
  .WrapperMobile {
    display: block;
  }

  .WrapperDesktop {
    display: none;
  }

  .ImgRight {
    width: 30%;
    top: 0;
  }

  .TopBox {
    flex-direction: column;
  }

  .Left {
    width: 100%;
  }

  .BackgroundImg1 {
    width: 100%;
  }

  .MergeTea {
    width: 30%;
    height: 60%;
    gap: 10px;
    bottom: 12%;
    left: 40%;
    justify-content: start;
  }

  .Right {
    width: 100%;
    padding: 0 16px;
  }
}
