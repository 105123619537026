.Wrapper {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 46px 0;
}

.Text {
  padding: 0 16px;
  //  margin-bottom: 86px;
}

.TextCenter {
  padding: 0 16px;
  margin-bottom: 86px;
  text-align: center;
}

.Title {
  color: #447323;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 19px;
}

.Desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.Image {
  width: 100%;
  margin-bottom: 30px;
  position: relative;
  display: flex;
  justify-content: center;
}

.BackgroundImage {
  width: 100%;
  object-fit: cover;
  border-top-left-radius: 30px;
  border-top-right-radius: 30px;
}

.floatImageRight {
  position: absolute;
  top: 30%;
  right: 5%;
  width: 55%;
  height: auto;
}

.floatImageLeft {
  position: absolute;
  top: 30%;
  left: 5%;
  width: 55%;
  height: auto;
}

.BtnSeeMore {
  margin-top: 10px;
  width: 192px;
  height: 51px;
  border-radius: 8px;
  background-color: #e1641c;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.BtnSeeMore:hover {
  opacity: 0.9;
}
