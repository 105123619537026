.MobileResponsive {
  display: none;
}

@media only screen and (max-width: 768px) {
  .MobileResponsive {
    display: block;
    margin-top: 100px;
  }

  .DesktopResponsive {
    display: none;
  }
}
