.WrapperDesktop {
  position: relative;
}

.WrapperMobile {
  display: none;
  height: auto;
}

.BackgroundImage {
  width: 100%;
  height: 658px;
  object-fit: cover;
}

.BackgroundImageMobile {
  height: 55rem;
  object-fit: cover;
}

.TextWrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 30%;
  left: 15%;
}

.TextWrapperMobile {
  width: 90%;
  height: auto;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 136px;
  left: 16px;
}

.Content1 {
  font-weight: 400;
  font-size: 57px;
  color: #447323;
  width: 495px;
}

.Content1Mobile {
  font-weight: 400;
  font-size: 36px;
  color: #447323;
  width: 90%;
  line-height: 46px;
}

.Content3 {
  margin-top: 9px;
  font-weight: 600;
  font-size: 24px;
  color: #447323;
  width: 495px;
  line-height: 32px;
}

.Content3Mobile {
  margin-top: 16px;
  font-weight: 700;
  font-size: 22px;
  color: #447323;
  width: 90%;
  line-height: 28px;
}

.Content2 {
  margin-top: 19px;
  width: 445px;
  font-weight: 500;
  font-size: 16px;
  color: #262626;
  line-height: 24px;
}

.Content2Mobile {
  margin-top: 16px;
  width: 100%;
  font-weight: 500;
  font-size: 14px;
  color: #262626;
  line-height: 20px;
}

.Button {
  position: absolute;
  bottom: 20%;
  left: 15%;
  width: 264px;
  height: 51px;
  background-color: #e1641c;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.ButtonMobile {
  position: absolute;
  top: 450px;
  left: 16px;
  width: 30%;
  line-height: 48px;
  background-color: #e1641c;
  border-radius: 8px;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.Button:hover {
  opacity: 0.9;
}

.Baobi1 {
  position: absolute;
  width: 12%;
  object-fit: contain;
  bottom: 7%;
  right: 27%;
}

.Baobi1Mobile {
  position: absolute;
  width: 30%;
  object-fit: contain;
  bottom: 5%;
  left: 20%;
}

.Baobi3 {
  position: absolute;
  width: 10%;
  object-fit: contain;
  bottom: 10%;
  right: 15%;
}

.Baobi3Mobile {
  position: absolute;
  width: 25%;
  object-fit: contain;
  bottom: 7%;
  left: 55%;
}

@media only screen and (max-width: 770px) {
  .WrapperDesktop {
    display: none;
  }
  .WrapperMobile {
    display: block;
    position: relative;
  }
}
