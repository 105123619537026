.Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 310px;
  gap: 16px;
}
.Title {
  font-size: 42px;
  color: #447323;
  line-height: 60px;
  font-weight: 400;
  margin-bottom: 0;
  text-align: center;
}
.Content {
  width: 60%;
  font-size: 14px;
  line-height: 20px;
  font-weight: 500;
  color: #262626;
  text-align: center;
  margin-bottom: 0;
}

@media only screen and (max-width: 480px) {
  .Title {
    font-size: 36px;
    line-height: 46px;
  }
  .Content {
    width: 90%;
  }
  .Container {
    padding: 0px 16px;
  }
}
