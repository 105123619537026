.Wrapper {
  margin-top: 100px;
  padding: 0 156px;
  display: flex;
  justify-content: space-between;
}

.Left {
  width: 30%;
}

.Title {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #447323;
  margin-bottom: 19px;
}

.Desc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}

.Right {
  width: 65%;
  display: flex;
  justify-content: end;
  flex-wrap: wrap;
  gap: 40px;
}

.Box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 15%;
  gap: 8px;
}

.Image {
  object-fit: contain;
  width: 100px;
  aspect-ratio: 1/1;
}

.SubText {
  font-family: "Montserrat", sans-serif;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0.25px;
  line-height: 20px;
}

@media only screen and (max-width: 770px) {
  .Wrapper {
    flex-direction: column;
    align-items: center;
    padding: 0;
  }
  .Left {
    width: 100%;
    margin-bottom: 56px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .Title {
    width: 50%;
    text-align: center;
  }
  .Desc {
    width: 70%;
    text-align: center;
  }
  .Right {
    justify-content: center;
    width: 80%;
    gap: 50px;
  }
  .Box {
    width: 40%;
  }
  .Image {
    width: 50%;
    aspect-ratio: 1/1;
  }
  .SubText {
    width: 80%;
  }
}
