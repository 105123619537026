.DesktopResponsive {
  margin-top: 100px;
}

.TopContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 341px;
  padding-right: 341px;
}

.TopContentTitle {
  color: #447323;
  font: 400px;
  font-size: 36px;
  margin-bottom: 16px;
  text-align: center;
}

.TopContentDesc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-align: center;
}

.InfoWrapper {
  padding: 0 156px;
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 64px;
}

.ItemRight {
  display: flex;
  flex-direction: column;
  width: 35%;
}

.ItemMiddle {
  color: #e1641c;
  font-weight: 400;
  font-size: 96px;
  line-height: 72px;
  min-width: 155px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 2px solid #447323;
  padding-bottom: 38px;
}

.InfoWrapperItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.InfoWrapperItemImg {
  width: 35%;
  height: 248px;
  border-radius: 8px;
  object-fit: cover;
}

.ItemRightTitle {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #447323;
  margin-bottom: 16px;
}

.ItemRightDesc {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}

.MobileResponsive {
  display: none;
}

@media only screen and (max-width: 770px) {
  .MobileResponsive {
    display: block;
  }
  .DesktopResponsive {
    display: none;
  }
}
