.Wrapper {
  margin-top: 100px;
  padding: 0 156px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.TopContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding-left: 223px;
  padding-right: 223px;
}

.TopContentTitle {
  color: #447323;
  font: 400px;
  font-size: 36px;
  margin-bottom: 16px;
  text-align: center;
}

.TopContentDesc {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-align: center;
}

.ContentWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 40px;
}

.Content {
  width: 23%;
  display: flex;
  flex-direction: column;
  margin-bottom: 75px;
}

.ContentImage {
  height: 326px;
  object-fit: cover;
}

.ContentTitle {
  margin-top: 26px;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #447323;
}

.ContentDesc {
  margin-top: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}

.BtnSeeMore {
  margin-top: -30px;
  width: 264px;
  height: 51px;
  border-radius: 8px;
  background-color: #e1641c;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.BtnSeeMore:hover {
  opacity: 0.9;
}

@media (min-width: 700px) and (max-width: 1000px) {
  .Wrapper {
    padding: 0 80px;
  }

  .TopContent {
    padding-left: 0px;
    padding-right: 0px;
  }
  .Content {
    width: 45%;
  }
}

@media (max-width: 699px) {
  .Wrapper {
    padding: 0 10px;
  }

  .TopContent {
    padding-left: 10px;
    padding-right: 10px;
  }
  .Content {
    width: 45%;
  }

  .ContentWrapper {
    justify-content: center;
    gap: 20px;
  }
}
