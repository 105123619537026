.Wrapper {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.TopContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
}

.TopContentTitle {
  width: 70%;
  color: #447323;
  font-weight: 400;
  font-size: 36px;
  margin-bottom: 16px;
  text-align: center;
}

.TopContentDesc {
  width: 95%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-align: center;
}

.Infos {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
}

.Info {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  gap: 10px;
}

.InfoNumber {
  width: 70%;
  color: #447323;
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  margin-bottom: 20px;
  text-align: center;
}

.InfoText {
  width: 90%;
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
}

.Icon {
  width: 95%;
  clip-path: polygon(
    3% 0%,
    97% 0%,
    100% 3%,
    100% 97%,
    97% 100%,
    3% 100%,
    0% 97%,
    0% 3%
  );
}

.floatNumber {
  color: #e1641c;
  font-weight: 600;
  font-size: 100px;
  position: absolute;
  left: 0;
  bottom: -2.5rem;
  z-index: 1000;
}

.Parent {
  width: 100%;
  position: relative;
}
