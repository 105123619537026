.Container {
  background: #efffea;
  height: 658px;
  position: relative;
  margin-bottom: 78px;
}

.Logo {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 420px;
  height: 139px;
  width: auto;
}

.Wrapper {
  height: 100%;
  margin: 0 10%;
}
.InnerWrapper {
  position: relative;
}
.BagWrapper {
  position: absolute;
}
.BagContainer {
  width: 186px;
  height: 186px;
  border-radius: 50%;
  background-color: #ffffff;

  span {
    position: absolute;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: #e1641c;
    left: 50%;
    width: 232px;
    bottom: -25%;
    transform: translateX(-50%);
    text-align: center;
  }
}
.BagImageWrapper {
  position: absolute;
  width: 130px;
  left: 50%;
  top: -40%;
  transform: translateX(-50%);
}
.Bag {
  height: auto;
  width: 150px;
  object-fit: contain;
}
.Bag1 {
  top: 340px;
  left: 0;
  .BagImageWrapper {
    top: -30%;
  }
}
.Bag2 {
  top: 100px;
  left: 25%;
  transform: translateX(-50%);
}
.Bag3 {
  top: 64px;
  left: 50%;
  transform: translateX(-50%);

  .BagImageWrapper {
    top: -30%;
  }
}
.Bag4 {
  top: 100px;
  left: 75%;
  transform: translateX(-50%);

  span {
    bottom: -26%;
  }
}
.Bag5 {
  top: 340px;
  left: 100%;
  transform: translateX(-100%);
}

@media (max-width: 699px) {
  .Container {
    height: 1197px;
  }
  .Logo {
    top: 1022px;
  }
  .Bag1 {
    top: 703px;
  }
  .Bag2 {
    top: 363px;
  }
  .Bag3 {
    top: 44px;
  }
  .Bag4 {
    top: 363px;
  }
  .Bag5 {
    top: 703px;

    span {
      width: 128px;
      bottom: -26%;
    }
  }
  .Wrapper {
    height: 100%;
    margin: 0 16px;
  }
}
