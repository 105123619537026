.Wrapper {
  margin-top: 300px;
  background-color: var(--primary-color-2);
  color: #fff;
  padding: 0 10% 50px;
  position: relative;
  padding-top: 120px;
  line-height: 20px;
}

.FooterImage {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translate(-50%, -50%);
  width: 360px;
  height: 212px;
}

.Container {
  display: flex;
  border-top: 1px solid #fff;
  padding-top: 50px;
}

.Box {
  margin-left: 3rem;
}

.Logo {
  height: 70px;
  object-fit: contain;
}

.FooterInfo {
  display: flex;
  gap: 8%;
}

.Column1 {
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 5px;
}

.Column2 {
  display: flex;
  flex-direction: column;
  width: 25%;
  gap: 15px;
}

.TitleColumn {
  font-size: 22px;
  font-weight: 700;
}

.ItemColumn {
  font-weight: 500;
  font-size: 14px;
}

.ContactBox {
  float: right;
  gap: 16px;
}

.Text {
  font-size: 22px;
  line-height: 28px;
  font-weight: bold;
}

.NetworkList {
  margin-top: 10px;
  display: flex;
  gap: 30px;
}

.NetworkItem {
  height: 40px;
}

.Column2Wrapper {
  width: 72%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.BoxIcon {
  display: flex;
  gap: 6px;
}

.Icon1 {
  width: 24px;
  height: 24px;
}

@media only screen and (max-width: 900px) {
  .Container {
    flex-direction: column;
    border-top: none;
    padding-top: 0;
  }
  .FooterInfo {
    flex-direction: column;
    gap: 48px;
  }
  .Column1 {
    width: auto;
  }
  .Box {
    margin-left: 0;
    padding-top: 36px;
    border-top: 1px solid #fff;
  }
  .Column2 {
    width: auto;
  }
  .Logo {
    margin-bottom: 36px;
  }
  .Column2Wrapper {
    gap: 25%;
    row-gap: 48px;
  }
  .ContactBox {
    margin-top: 32px;
    float: none;
  }
}
