.MobileResponsive {
  display: none;
}

@media (max-width: 768px) {
  .DesktopResponve {
    display: none;
  }

  .MobileResponsive {
    display: block;
  }
}
