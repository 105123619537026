.Wrapper {
  position: relative;
  padding-top: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.Tea1 {
  position: absolute;
  width: 101px;
  height: 125px;
  top: 100px;
  left: 103px;
}

.Tea2 {
  position: absolute;
  width: 161px;
  height: 150px;
  top: 247px;
  right: 44px;
}

.Content {
  width: 66%;
}

.ContentInfo1 {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 42px;
  line-height: 60px;
  color: #447323;
}

.TextInfo1 {
  width: 500px;
  text-align: center;
}

.ContentInfo2,
.TextInfo2 {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
}

.TextInfo2 {
  text-align: left;
}

.Content2 {
  margin-top: 73px;
  padding-left: 156px;
  padding-right: 156px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
  align-items: center;
}

.Content2Tag1 {
  display: flex;
  align-items: center;
  gap: 120px;
}

.Pic1 {
  width: 456px;
  height: 284px;
}

.Content2Tag1Text {
  height: 182px;
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.Content2Tag1TextTitle {
  font-weight: 400;
  font-size: 36px;
  line-height: 46px;
  color: #447323;
}

.BtnSeeMore {
  width: 264px;
  height: 51px;
  border-radius: 8px;
  background-color: #e1641c;
  color: #fff;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 36px;
  cursor: pointer;
}
.BtnSeeMore:hover {
  opacity: 0.9;
}

@media (min-width: 700px) and (max-width: 1200px) {
  .Content2 {
    padding-left: 10px;
    padding-right: 10px;
  }

  .Content2Tag1 {
    gap: 30px;
  }

  .Tea2 {
    top: 180px;
    right: 18px;
  }
  .Tea1 {
    left: 60px;
  }
  .TextInfo2 {
    margin-top: 8px;
  }

  .Content2Tag1Text {
    gap: 0px;
  }
}
