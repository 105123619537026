.Wrapper {
  position: relative;
}
.TopContent {
  display: flex;
  margin-bottom: 50px;
  align-items: center;
  flex-direction: column;
}

.TopContentTitle {
  color: #447323;
  width: 60%;
  font-size: 36px;
  margin-bottom: 16px;
  text-align: center;
}

.TopContentDesc {
  width: 50%;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #262626;
  text-align: center;
}

.Content {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.ContentTag3Image,
.ContentTag1Image {
  width: 50%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  margin-left: 15%;
}
.ContentTag2Image {
  width: 50%;
  aspect-ratio: 1/1;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 15%;
}
.ContentTagIcon {
  width: 72px;
  height: 72px;
  margin-bottom: 16px;
}
.ContentTag2Icon {
  width: 72px;
  height: 72px;
  margin-bottom: 16px;
}

.ContentTagIcon3 {
  width: 80px;
  height: 55px;
  margin-bottom: 16px;
}
.ContentTag,
.ContentTag2 {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  width: 75%;
}
.ContentTagText {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: left;
}
.ContentTagTextTitle {
  text-align: left;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;
  color: #447323;
  margin-bottom: 16px;
}
.ContentTagTextDesc {
  width: 458px;
  text-align: left;
  font-size: 14px;
}

@media only screen and (max-width: 770px) {
  .TopContentTitle {
    width: 70%;
  }
  .TopContentDesc {
    width: 90%;
  }

  .ContentTag {
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column-reverse;
  }
  .ContentTag2 {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 74px 0;
  }
  .ContentTag1Image,
  .ContentTag2Image,
  .ContentTag3Image {
    width: 90%;
    margin: 0;
  }
  .ContentTagTextTitle,
  .ContentTagTextDesc {
    text-align: center;
  }
  .ContentTagText {
    margin-top: 38px;
    align-items: center;
    width: 95%;
  }

  .ContentTagTextDesc {
    width: 100%;
  }
}
